<template>
    <a-spin tip="登录中" :spinning="signInProgress">
        <div style="height: 500px;">
            <div class="ec-login-failed-reason" v-if="signInFailed">
                <a-result status="error" title="登录失败" :sub-title="failedMessage">
                </a-result>
            </div>
        </div>
    </a-spin>
</template>

<script>
import { LOGIN_TYPE } from "@/config";
import { cookies, clearCookies, FETCH_CODE } from "@/config";
import { onLogin } from "@/pages/user/api";

export default {
    data() {
        return {
            mobile: "",
            sign: "",
            signInFailed: false,
            signInProgress: true,
            failedMessage: "如果多次登录失败请联系信息部"
        };
    },
    methods: {
        async handleSignIn() {
            this.mobile = this.$route.query.mobile;
            this.sign = this.$route.query.sign;

            if (!this.mobile || this.mobile.length < 1) {
                this.signInProgress = false;
                this.signInFailed = true;
                this.failedMessage = "缺少参数";

                return false;
            }

            if (!this.sign || this.sign.length < 1) {
                this.signInProgress = false;
                this.signInFailed = true;
                this.failedMessage = "缺少参数";

                return false;
            }

            //清除cookie
            clearCookies();

            // 请求登录接口
            const loginRes = await onLogin({
                account: this.mobile,
                password: "",
                code: "",
                sign: this.sign,
                type: LOGIN_TYPE.ESS_ONE_KEY_LOGIN
            });

            if (loginRes.flag !== FETCH_CODE.SUCCESS.KEY) { 
                this.signInProgress = false;
                this.signInFailed = true;
                this.failedMessage = loginRes.msg;

                return false;
            }

            // 设置cookie直接给cookie对象赋值即可
            cookies.EC_TOKEN = loginRes.data.session;

            this.submitAfter()
        },
        submitAfter() {
            let redirectUri = "/";

            this.$store.dispatch("user/getUserInfo", {
                callback: () => {
                    this.$store.dispatch("home/getMenuList", {
                        // fix#174 https://echeck-china.coding.net/p/echeck-platform/bug-tracking/issues/174/detail
                        callback: () => {
                            this.$store.commit("home/onMenu", {
                                value: redirectUri,
                                useKeyName: "href"
                            });
                            this.$router.replace(redirectUri);
                        },
                    });
                },
            });
        }
    },
    async mounted() {
        await this.handleSignIn();
    }
}
</script>

<style scoped>
.ec-login-failed-reason {
    width: 50%;
    margin: 0 auto;
}
</style>